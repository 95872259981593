import React, { useState } from 'react';
import { Header } from './components/header';
import { MainContent } from './components/mainContent';
import ShopifyBuyButton from './components/shopifyBuyButton';
import { Modal } from './components/modal';
import { Footer } from './components/footer';

export function App() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalContent = 'sizeGuide'

    const toggleModal = () => {
        setIsModalOpen(prevState => {
            console.log("New state "+ !prevState);
            return !prevState;
        });
    };


    return (
        <div className="wrapper gradiant">
            <Header />
            <MainContent />
            {/* <ShopifyBuyButton /> */}
            {/* <Modal
                isOpen={isModalOpen}
                onClose={toggleModal}
                modalContent={modalContent}
            /> */}
            {/* <Footer onToggleModal={toggleModal} /> */}
        </div>
    );
}
