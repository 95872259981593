import '../css/logo.css';
import '../css/fonts.css';
import { SubHeader } from './subHeader';

export function MainContent() {
    return (
        <main className="content">
            <h1 id="title">Kribba</h1>
            <div className='threeColumnGrid_1_1_1'>
            <div></div>
            <div id='sh-center'>
            {SubHeader("NEW STUFF COMING SOON")}
            </div>
            <div></div>
        </div>
            
        </main>
    );
}
