import React from "react";
import {createRoot} from 'react-dom/client';
import './css/light-mode.css';
import './css/index.css';
import './css/desktop-style.css';
import './css/switch-button.css'
import {App} from './App';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)